import * as newsSelectors from './news';
import * as documentationSelectors from './documentation';
import * as slidersSelectors from './sliders';
import * as ipsSelectors from './ips';
import * as phoneCodesSelectors from './phonecodes';
import * as goodsSelectors from './goods';
import * as handbookSelectors from './handbook';
import * as yookassaSelectors from './yookassa';
import * as statisticsSelectors from './statistics';
import * as messagesSelectors from './messages';
export * from './root';

export const fromStore = {
  ...newsSelectors,
  ...documentationSelectors,
  ...goodsSelectors,
  ...handbookSelectors,
  ...slidersSelectors,
  ...yookassaSelectors,
  ...statisticsSelectors,
  ...ipsSelectors,
  ...phoneCodesSelectors,
  ...phoneCodesSelectors,
  ...messagesSelectors,
};
