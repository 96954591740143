import React, { Component } from 'react';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import {connect} from 'react-redux';
import Loader from '../utils/Loader';
import ReactPaginate from 'react-paginate';
import { setCashbackLogsUserPaging, fetchCashbackLogs } from '../../action/logs';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class UserCashbacklogs extends Component {
    state = {
        currentPage: 0
    }

    componentDidMount() {
        this.props.fetchCashbackLogs({ user: this.props.match.params.id });
    }

    componentWillUnmount() {
        const { cashbackLogsUserPaging } = this.props;
        this.props.setCashbackLogsUserPaging({ ...cashbackLogsUserPaging, start: 0 });
    }

    convertOperationType(operationType) {
        switch (operationType) {
            case 'setBalance':
                return 'Зачисление';
            case 'minusBalance':
                return 'Списание';
            default:
                break;
        }
    }

    convertCashbackLevel(level) {
        switch (level) {
            case 'level1':
                return 'Уровень 1';
            case 'level2':
                return 'Уровень 2';
            case 'level3':
                return 'Уровень 3';
            case 'level4':
                return 'Уровень 4';
            default:
                break;
        }
    }

    renderField(field) {
        if (field == null) {
            return '-';
        } else {
            return field
        }
    }

    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span style={{ width: '50px' }}>Id</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '50px' }}>Баллы</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Тип операции</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '80px' }}>Уровень операции</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '150px' }}>Дата операции</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '100px' }}>Пользователь системы</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span style={{ width: '50px' }}>Id заказа</span>
                    </th>
                </tr>
            </thead>
        );
    }

    renderBody() {
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.length === 0 ? (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Логов не найдено</td>
                        </tr>
                    ) : (
                
                     this.props.items.map(item => {
                        return (
                            <tr key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell">
                                <span style={{ width: '50px' }}>{item.id}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '50px' }}>{item.balance}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '80px' }}>{this.convertOperationType(item.operationType)}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '80px' }}>{this.convertCashbackLevel(item.cashbackLevel)}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '150px' }}>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>
                                    {item.executor ? item.executor.username : '-'}
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '50px' }}>{item.order ? item.order == null ? item.order : '-' : '-'}</span>
                                {/* <span style={{ width: '50px' }}>{this.renderField(item.order)}</span> */}
                                {/* <span style={{ width: '50px' }}>{item.order ? this.renderField(item.order) : '-'}</span> */}
                            </td>
                        </tr>
                        )
                    })                 
                    )
                }
            </tbody>
        );
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setCashbackLogsUserPaging(updatedPaging);
        this.props.fetchCashbackLogs({ user: this.props.match.params.id });
    }

    handlePageClick = data => {
        const { cashbackLogsUserPaging: paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setCashbackLogsUserPaging(updatedPaging);
            this.props.fetchCashbackLogs({ user: this.props.match.params.id });
        });
    };

    renderPagination() {
        const { 
            cashbackLogsUserCount: count, 
            cashbackLogsUserPaging: paging 
        } = this.props;
        const pages = Math.ceil(+count / +paging.limit);
        return (

            <div className="kt-datatable__pager kt-datatable--paging-loaded" style={{ borderTopWidth: 3 }}>
                
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={6}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav kt-datatable__pager-nav-row'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                    disableInitialCallback={this.props.loadingItems}
                    />

                <div className="kt-datatable__pager-info" style={{ marginTop: 10 }}>
                    <div className="kt-datatable__pager-size">
                        <select disabled={this.props.loadingItems} onChange={this.setPageLimit} 
                        value={paging.limit} 
                        className="form-control">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        
                    </div>

                    <div className="kt-datatable__pager-size">
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                                ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                    </div>
                    
                </div>

            </div>
        );
    }


    render() {
        return (
            <div className="row">
                <div className="col-xl-12">
                    <div className="kt-portlet kt-portlet--height-fluid">
                        <div className="kt-portlet kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            {this.props.loadingItems ?  <div style={styles.loading}><Loader /></div> : (
                                <table className="kt-datatable__table" style={{ height: '500px', overflow: 'auto' }}>
                                    {this.renderThead()}
                                    {this.renderBody()}
                                </table>
                            )}
                            {
                                this.props.items.length > 0 && this.renderPagination()
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

    const mapStateToProps = state => ({
        items: state.logs.cashbackLogsUser.logs,
        cashbackLogsUserCount: state.logs.cashbackLogsUser.count,
        cashbackLogsUserPaging: state.logs.cashbackLogsUser.paging,
        loadingItems: state.loaders.cashbackLogsUser,
    });

    const mapDispatchToProps = {
        setCashbackLogsUserPaging: (cashbackLogsUserPaging) => setCashbackLogsUserPaging(cashbackLogsUserPaging),
        fetchCashbackLogs: (data) => fetchCashbackLogs(data),
    }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserCashbacklogs));