export const storeSelector = (state) => state;

export const newsSelector = (state) => state.news;

export const documentationSelector = (state) => state.documentation;

export const slidersSelector = (state) => state.sliders;

export const ipsSelector = (state) => state.ips;

export const phonecodesSelector = (state) => state.phonecodes;

export const yookassaSelector = (state) => state.yookassa;

export const goodsSelector = (state) => state.good;

export const loadersSelector = (state) => state.loaders;

export const userRoleSelector = (state) => state.user.info.role.id;

export const modalsSelector = (state) => state.modals;

export const handbookSelector = (state) => state.handbook;

export const statisticsSelector = (state) => state.statistics;

export const messagesSelector = (state) => state.messages;