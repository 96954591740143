import { urls } from '../constants/app';
import request from './utils';
import { setError, resetError } from './errors';
import { addChanel } from './messages';

export function setAddCity(data) {
    return (dispatch) => {
        dispatch({ type: 'SET_ADD_HANDBOOK', data });
        if (!data.name) {
            dispatch(resetError('addCity'));
        }
    }
}

export function fetchCities(isGetAll = false, withoutCount = false) {
    return async (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'cities', value: true });
    
    const paging = getState().handbook.paging.city;
    const sorting = getState().handbook.sorting.city;
    const currentCount = getState().handbook.count.cities;

    const params = {};
    if (!isGetAll) {
        params['_start'] = paging.start;
        params['_limit'] = paging.limit;
    } 
    
    params['_sort'] = `${sorting.field}:${sorting.type}`;

    try {
        if (!withoutCount && currentCount === 0) {
            const count = await request({ method: 'get', url: urls.cities+'/count' });
            dispatch({ type: 'SET_HANDBOOK_COUNT', count, field: 'cities' });
        }
        const cities = await request({ method: 'get', url: urls.cities, params });
        dispatch({ type: 'SET_CITIES', cities });
        dispatch({ type: 'SET_LOADER', field: 'cities', value: false });
    } catch(error) {
        dispatch(setError(error, 'cities'));
        dispatch({ type: 'SET_LOADER', field: 'cities', value: false });
    }
}
}

export function fetchCity(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'city', value: true });

    return request({ method: 'get', url: `${urls.cities}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_CITY', city: response });
            dispatch({ type: 'SET_LOADER', field: 'city', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'cities'));
            dispatch({ type: 'SET_LOADER', field: 'city', value: false });
            return null
        });
    }
}

export function fetchZones(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'zones', value: true });

    return request({ method: 'get', url: `${urls.zone}`, params: { 'city.id': id } })
        .then((response) => {
            dispatch({ type: 'SET_ZONES', zones: response });
            dispatch({ type: 'SET_LOADER', field: 'zones', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'cities'));
            dispatch({ type: 'SET_LOADER', field: 'zones', value: false });
            return null
        });
    }
}

export function fetchFilial(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'filial', value: true });

    return request({ method: 'get', url: `${urls.filials}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_FILIAL', filial: response });
            dispatch({ type: 'SET_LOADER', field: 'filial', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'filial'));
            dispatch({ type: 'SET_LOADER', field: 'filial', value: false });
            return null
        });
    }
}

export function fetchZone(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'zone', value: true });

    return request({ method: 'get', url: `${urls.zone}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_ZONE', zone: response });
            dispatch({ type: 'SET_LOADER', field: 'zone', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'zone'));
            dispatch({ type: 'SET_LOADER', field: 'zone', value: false });
            return null
        });
    }
}

export function setCityEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'SET_EDIT_CITY', data });
    }
}

export function setFilialEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'SET_EDIT_FILIAL', data });
    }
}

export function addFilialsEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'ADD_EDIT_FILIALS', data });
    }
}

export function changeFilialsEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'CHANGE_EDIT_FILIALS', data });
    }
}

export function removeFilialsEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'REMOVE_EDIT_FILIALS', data });
    }
}

export function setZoneEdit(data) {
    return (dispatch) => {
        dispatch({ type: 'SET_EDIT_ZONE', data });
    }
}

export function fetchFilials(cityId, isGetAll = false, withoutCount = false) {
    return async (dispatch, getState) => {
    dispatch({ type: 'SET_LOADER', field: 'filials', value: true });
    const paging = getState().handbook.paging.filial;
    const sorting = getState().handbook.sorting.filial;
    const filters = getState().handbook.filters.filial;
    const currentCount = getState().handbook.count.filials;

    const params = {};
    const dataParams = {};
    if (!isGetAll) {
        params['_start'] = paging.start;
        params['_limit'] = paging.limit;
    }

    if (filters.city) {
        dataParams['city.id'] = filters.city.value;
        params['city.id'] = filters.city.value;
    }
    
    params['_sort'] = `${sorting.field}:${sorting.type}`;


    if (cityId) {
        params['city.id'] = cityId;
    }

    try {
        if (!withoutCount && currentCount === 0) {
            const count = await request({ method: 'get', url: urls.filials+'/count', params: dataParams });
            dispatch({ type: 'SET_HANDBOOK_COUNT', count, field: 'filials' });
        }
        const filials = await request({ method: 'get', url: urls.filials, params });
        dispatch({ type: 'SET_FILIALS', filials });
        dispatch({ type: 'SET_LOADER', field: 'filials', value: false });
    } catch(error) {
        dispatch(setError(error, 'filials'));
        dispatch({ type: 'SET_LOADER', field: 'filials', value: false });
    }
    }
}

export function addCity(data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'addCity', value: true });

    return request({ method: 'post', url: urls.cities, data })
        .then((response) => {
            const chanel = {
                title: response.name,
                key: 'city',
                value: response.id
            };
            return addChanel(chanel).then(() => {
                dispatch({ type: 'SET_LOADER', field: 'addCity', value: false });
                return true;
            }).catch(() => {
                dispatch(setError({ status: 506 }, 'addCity'));
                dispatch({ type: 'SET_LOADER', field: 'addCity', value: false });
                return false;
            });
        })
        .catch(error => {
            dispatch(setError({ status: 506 }, 'addCity'));
            dispatch({ type: 'SET_LOADER', field: 'addCity', value: false });
            return false;
        });
    }
}

export function updateCity(id, data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'editCity', value: true });
    dispatch(resetError('editCity'));
    return request({ method: 'put', url: `${urls.cities}/${id}`, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'editCity', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'editCity'));
            dispatch({ type: 'SET_LOADER', field: 'editCity', value: false });
            return false;
        });
    }
}

export function updateFilial(id, data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'editFilial', value: true });

    return request({ method: 'put', url: `${urls.filials}/${id}`, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'editFilial', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'editFilial'));
            dispatch({ type: 'SET_LOADER', field: 'editFilial', value: false });
            return false;
        });
    }
}

export function updateFilialsTimeDelivery(data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'filials', value: true });

    return request({ method: 'put', url: `${urls.filials}/updateTimeDelivery`, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'filials', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'filials'));
            dispatch({ type: 'SET_LOADER', field: 'filials', value: false });
            return false;
        });
    }
}

export function updateZone(id, data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'editZone', value: true });

    return request({ method: 'put', url: `${urls.zone}/${id}`, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'editZone', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'editZone'));
            dispatch({ type: 'SET_LOADER', field: 'editZone', value: false });
            return false;
        });
    }
}

export function deleteFilial(item) {
    return request({ method: 'delete', url: `${urls.filials}/${item.id}` })
        .then((response) => {
            return true;
        })
        .catch(error => {
            return false;
        });
}

export function activateFilial(item) {
    return request({ method: 'put', url: `${urls.filials}/${item.id}`, data: { stop: !item.stop } })
        .then((response) => {
            return true;
        })
        .catch(error => {
            return false;
        });
}

export function deleteZone(item) {
    return request({ method: 'delete', url: `${urls.zone}/${item.id}` })
        .then((response) => {
            return true;
        })
        .catch(error => {
            return false;
        });
}

export function deleteCity(item) {
    return (dispatch) => {
        dispatch({ type: 'SET_LOADER', field: 'cities', value: true });

        request({ method: 'delete', url: `${urls.cities}/${item.id}` })
            .then((response) => {
                dispatch(fetchCities());
                dispatch({ type: 'SET_LOADER', field: 'cities', value: false });
            })
            .catch(error => {
                dispatch(setError({ status: 507 }, 'cities'));
                dispatch({ type: 'SET_LOADER', field: 'cities', value: false });
            });
    }
}

export function addFilial(data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'addFilial', value: true });

    return request({ method: 'post', url: urls.filials, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'addFilial', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 506 }, 'addFilial'));
            dispatch({ type: 'SET_LOADER', field: 'addFilial', value: false });
            return false;
        });
    }
}

export function addZone(data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'addZone', value: true });

    return request({ method: 'post', url: urls.zone, data })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'addZone', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 506 }, 'addZone'));
            dispatch({ type: 'SET_LOADER', field: 'addZone', value: false });
            return false;
        });
    }
}

export function setHandbookPaging(field, paging) {
    return dispatch => {
        dispatch({ type: 'SET_HANDBOOK_PAGING', field, paging });
    }
}

export function setHandbookFilters(field, filters) {
    return dispatch => {
        dispatch({ type: 'SET_HANDBOOK_FILTERS', field, filters });
    }
}