import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { fetchOrder, deleteOrder, updateOrder } from '../../action/orders';
import { fetchGoods, fetchCategories } from '../../action/goods';
import moment from 'moment';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { sendCashBack } from '../../action/user';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';
import { operationType } from '../../constants/app';

import Loader from '../utils/Loader';
const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const levelTitle = {
    level1: 'Уровень 1 - заказчик',
    level2: 'Уровень 2 - рекомендатор',
    level3: 'Уровень 3 - рекомендатор рекомендатора',
}

const сashTitle = {
    no: 'под расчет',
    c1000: '2000 ₸',
    c1500: '5000 ₸',
    c2000: '10000 ₸',
    c5000: '20000 ₸',
};

const paymentTitle = {
    cash: 'Наличные',
    terminal: 'Терминал (картой при получении)',
    online: 'Онлайн-оплата'
};


class DetailOrder extends Component {
    state = {
        active: 0,
        isDelete: false
    };

    componentDidMount() {
        this.props.fetchOrder(this.props.match.params.id);
        this.props.fetchGoods();
        this.props.fetchCategories();
    }

    renderDeleteButton() {
        if (this.state.isDelete) {
            return (
                <Fragment>
                    <button onClick={this.onDelete}  className="btn btn-danger">Удалить</button>
                    <button onClick={this.showDeleteButtons(false)} style={{ marginRight: 20 }} className="btn btn-secondary">Отменить</button>
                </Fragment>
            );
        }

        return (
            <button onClick={this.onDelete} style={{ marginRight: 20 }} className="btn btn-danger">Удалить заказ</button>
        );
    }

    changeStatus = (e) => {
        const data = {
            status: e.target.value
        };
        if (data.status === 'cancel') {
            swal({
                title: "Изменение статуса заказа",
                text: "Вы действительно хотите отменить заказ?",
                icon: "warning",
                dangerMode: true,
                buttons: ["Отмена", "Отменить заказ"],
            }).then((willDelete) => {
                if (willDelete) {
                    this.props.updateOrder(this.props.order.id, data);
                }
            });
        } else {
            this.props.updateOrder(this.props.order.id, data);
        }
       
    }

    renderSubHeader() {
        const { role, order } = this.props;
        return (
            <div className="kt-subheader kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                    <div className="kt-subheader__main">
                        <h3 className="kt-subheader__title">Заказ №{this.props.order.id}</h3>
                        <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                        {
                             [4, 5].includes(role.id) && (
                                <div style={{ display: 'flex', width: 200 }} className="kt-portlet__head-toolbar">
                                    <select onChange={this.changeStatus} value={order.status} placeholder="Статус заказа" className="form-control">
                                        { order.status === 'new' && <option value="new">Новый заказ</option>}
                                        { (order.status === 'new' || order.status === 'done') && <option value="done">Выполнен</option>}
                                        { (order.status === 'cancel' || order.status === 'new') && <option value="cancel">Отменен</option>}
                                        { (order.status === 'done' || order.status === 'return') && <option value="return">Возврат</option>}
                                    </select>
                                </div>
                            )
                        }
                    </div>
                    <div className="kt-subheader__toolbar">
                        {
                            role.id === 4 && this.renderDeleteButton()
                        }
                    
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="btn btn-secondary">
                            Назад
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    onTab = (index) => (e) => {
        e.preventDefault();
        this.setState({ active: index });
    }

    renderAdress(item) {
        if (!item.street && !item.point) return '-';

        if(item.deliveryMethod === "delivery")
            return `${item.city ? item.city.name + ', ' : ''}${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
        
        if(item.deliveryMethod === "own")
            return `${item.city ? item.city.name + ', ' : ''}${item.point.street}, ${item.point.house}`;
    }

    showDeleteButtons = (value) => () => {
        this.setState({ isDelete: value });
    }


    onDelete = () => {
        swal({
            title: "Удаление заказа",
            text: "Вы действительно хотите удалить заказ?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                this.props.deleteOrder(this.props.match.params.id).then(result => {
                    if (result) {
                        this.props.history.push(`/orders`);
                    }
                })
            }
        });

        
    }

    sendBonus = () => {
        const { cashback, user, id } = this.props.order;
        sendCashBack(cashback, user.id, id, operationType.setBalance).then(result => {
            if (result) {
                swal({
                    title: "Успех",
                    text: "Сообщение отправлено",
                    icon: "success",
                    confirmButtonText: 'OK'
                });
                this.props.fetchOrder(this.props.match.params.id);
            }
        });
    }

    getStatusPay = (order) => {
        if (!order.paymentId) return 'Не оплачено';
        const paymentData = order.paymentData ? JSON.parse(order.paymentData) : null;
        if (!order.paymentData) return 'Не оплачено';

        if (paymentData.status === 'pending') {
            return 'Ожидает оплаты';
          }
      
        if (paymentData.status === 'succeeded') return 'Оплачено';
        if (paymentData.status === 'refundPending') return 'Возврат средств';
        if (paymentData.status === 'refund') return 'Возврат средств выполнен';
        return '';
     }

    renderOrder() {
        const { order } = this.props;

        return (
            <div className="kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--height-fluid">
                <div className="kt-portlet__head kt-portlet__space-x">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title kt-font-light">
                            Заказ №{order.id}
                        </h3>
                    </div>
                    
                    <div className="kt-portlet__head-toolbar">
                    
                    </div>

                </div>
                <div className="kt-portlet__body">
                    <div className="kt-widget28">
                        <div className="kt-widget28__visual" style={{ backgroundImage: 'url(/images/bg-2.jpg)' }}></div>
                        <div className="kt-widget28__wrapper kt-portlet__space-x">
                                            
                            <ul className="nav nav-pills nav-fill kt-portlet__space-x" role="tablist">
                                <li className="nav-item">
                                    <a onClick={this.onTab(0)} className={cn({ 'nav-link': true, 'active': this.state.active === 0 })} href="#menu11"><span><i className="fa flaticon-shopping-basket"></i></span><span>Информация</span></a> 
                                </li>
                                <li className="nav-item">
                                    <a onClick={this.onTab(1)} className={cn({ 'nav-link': true, 'active': this.state.active === 1 })} href="#menu21"><span><i className="fa flaticon-placeholder-3"></i></span><span>Доставка</span></a> 	
                                </li>
                                {
                                    order.user && order.cashback && (
                                        <li className="nav-item">
                                            <a onClick={this.onTab(2)} className={cn({ 'nav-link': true, 'active': this.state.active === 2 })} href="#menu31"><span><i className="fa flaticon-piggy-bank"></i></span><span>CashBack</span></a> 		
                                        </li>
                                    )
                                }
                                		                 
                            </ul> 
                        
                            <div className="tab-content">
                                <div className={cn({ 'tab-pane': true,  'active': this.state.active === 0, 'fade': this.state.active !== 0})}>
                                    <div className="kt-widget28__tab-items">
                                         <div className="kt-widget28__tab-item">
                                            <span>Источник</span>
                                            <span>{order.isSite ? 'Сайт' : 'Приложение'}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Номер заказа Frontpad</span>
                                            <span>{order.orderIdFrontpad || '-'}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Дата заказа</span>
                                            <span>{moment(order.created_at).format('DD.MM.YYYY HH:mm')}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Заказчик</span>
                                            {
                                                order.user ? (
                                                    <Link to={`/users/${order.user.id}`}>{order.userName}</Link>
                                                ) : (
                                                    <span>{order.userName || '-'}</span>
                                                )
                                            } 
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Телефон</span>
                                            <span>+{order.phone}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Кол-во приборов</span>
                                            <span>{order.unit ? `${order.unit} шт.` : 'Без приборов'}</span>
                                        </div>
                                        {
                                            order.promocode && (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Промокод</span>
                                                    <Link to={`/promocodes/`}>{order.promocode.name}</Link>
                                                </div>
                                            )
                                        }
                                            
                                        <div className="kt-widget28__tab-item">
                                            <span>Комментарий</span>
                                            <span>{order.comment ? order.comment : '---'}</span>
                                        </div>
                                        
                                    </div>					      	 		      	
                                </div>
                                <div className={cn({ 'tab-pane': true,  'active': this.state.active === 1, 'fade': this.state.active !== 1})}>
                                    <div className="kt-widget28__tab-items">
                                        <div className="kt-widget28__tab-item">
                                            <span>Город</span>
                                            <span>{order.city ? order.city.name : 'Не определен'}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Способ доставки</span>
                                            <span>{order.deliveryMethod === 'own' ? 'Самовывоз' : 'Доставка по адресу'}</span>
                                        </div>
                                        <div className="kt-widget28__tab-item">
                                            <span>Стоимость доставки</span>
                                            <span>{order.deliveryPrice === 0 ? 'Бесплатно' : `${order.deliveryPrice} ₸`}</span>
                                        </div>
                                        {
                                            order.payment && (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Способ оплаты</span>
                                                    <span>{paymentTitle[order.payment]}</span>
                                                </div>
                                            )
                                        }

                                        {
                                            order.payment === 'online' && (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Статус оплаты</span>
                                                    <span>{this.getStatusPay(order)}</span>
                                                </div>
                                            )
                                        }
                                       
                                        {
                                            order.payment && order.payment === 'cash' && (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Сдача с</span>
                                                    <span>{сashTitle[order.changeCash]}</span>
                                                </div>
                                            )
                                        }
                                        {
                                            order.deliveryMethod === 'own' ? (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Пункт самовывоза</span>
                                                    <span>{this.renderAdress(order)}</span> 								
                                                </div>
                                            ) : (
                                                <div className="kt-widget28__tab-item">
                                                    <span>Адрес доставки</span>
                                                    <span>{this.renderAdress(order)}</span> 							
                                                </div>
                                            )
                                        }
                                        <div className="kt-widget28__tab-item">
                                            <span>Когда доставить</span>
                                            <span>{order.deliveryTime ? moment(order.deliveryTime).format('DD.MM.YYYY в HH:mm') : 'Как можно скорее'}</span>								
                                        </div>
                                    </div>					      	
                                </div>
                                {
                                    order.user && order.cashback && (
                                        <div className={cn({ 'tab-pane': true,  'active': this.state.active === 2, 'fade': this.state.active !== 2})}> 
                                            <h5 style={{ marginBottom: 30 }}>
                                                {order.isBonusSended ? 'Начисленные бонусы' : 'Бонусы к зачислению'}
                                            </h5>
                                            <div style={{ paddingBottom: 20 }} className="kt-widget4">
                                                {
                                                    Object.keys(order.cashback).filter(key => order.cashback[key]).map(key => (
                                                        <div key={key} className="kt-widget4__item">
                                                            <div>{levelTitle[key]}</div> 		
                                                            <span className="kt-widget4__number kt-font-success">+{order.cashback[key]}</span>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    !order.isBonusSended && this.props.role === 4 && (
                                                        <button disabled={order.status !== 'done'} style={{ margin: '20px 0' }} onClick={order.status === 'done' && this.sendBonus} className="btn btn-label-brand btn-bold btn-sm">Начислить бонусы</button>      
                                                    )
                                                }     
                                            </div>
                                        </div>	
                                    )
                                }
                            </div>
                            
                        </div>				 	 
                    </div>
                </div>
            </div>
        );
    }

    renderGift(gift) {
        const data = this.props.goods.find(o => +o.id === +gift.id);
        if (!data) return null;

        const category = this.props.categories.find(o => o.id === data.category.id);
        const catName = category ? category.title : '-';

        return (
            <tr>
                <td>
                    <span style={{ width: '252px' }}>
                        <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                                <img alt={data.title} src={data.image}/>
                            </div>
                            <div className="kt-user-card-v2__details">
                                <div className="kt-user-card-v2__name" href="/">{data.title}</div>
                                <span className="kt-user-card-v2__email">
                                    {catName} в подарок
                                </span>
                            </div>
                        </div>
                    </span>
                </td>
                <td>0 ₸</td>
                <td>1</td>
                <td className="kt-align-right kt-font-brand kt-font-bold">0 ₸</td>
            </tr>
        );
    }

    getAllToppings(top) {
        const { meat, topping } = top;

        const toppings = [];
        if (Object.keys(meat).length) {
            Object.keys(meat).forEach(item => {
                toppings.push(meat[item]);
            })
        }

        if (Object.keys(topping).length) {
            Object.keys(topping).forEach(item => {
                toppings.push(topping[item]);
            })
        }
        return toppings;
    }

    getToppings(item) {
        const toppings = this.getAllToppings(item);
        if (toppings.length === 0) return '';
        return toppings.map(top => `${top.data.title.toLowerCase()} x${top.count}`).join(', ');
    }

    renderWok = (good, index) => {
        const { main, souce } = good.wokData;
        const firstSouce = souce[Object.keys(souce)[0]].title;
        const secondSouce = Object.keys(souce)[1];
        const mainWok = `${main.title} + Овощи + Соус ${firstSouce}`;

        let toppings = this.getToppings(good.wokData);
        if (secondSouce) {
            toppings = `доп. соус ${souce[secondSouce].title.toLowerCase()}${toppings ? `, ${toppings}` : ''}`;
        }

        return (
            <tr key={index}>
                <td>
                    <span style={{ width: '252px' }}>
                        <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                                <img alt="Вок" src="/images/wokk.png"/>
                            </div>
                            <div className="kt-user-card-v2__details">
                                <div className="kt-user-card-v2__name" href="/">{mainWok}</div>
                                <span className="kt-user-card-v2__email">
                                    {toppings}
                                </span>
                            </div>
                        </div>
                    </span>
                </td>
                <td>{good.price} ₸</td>
                <td>{good.count}</td>
                <td className="kt-align-right kt-font-brand kt-font-bold">{good.price * good.count} ₸</td>
            </tr>
        );
    }

    renderCategory = (good) => {
        if (good.categoryName) {
            if (good.categoryName.toLowerCase() === 'пицца') {
                return (
                    <span className="kt-user-card-v2__email">
                        {good.categoryName} - {good.isMod ? '36 см' : '30 см' }
                    </span>
                );
            }
            return (
                <span className="kt-user-card-v2__email">
                    {good.categoryName}
                </span>
            );
        }
        const category = this.props.categories.find(o => o.id === good.category);
        if (!category) return null;
        if (category.title.toLowerCase() === 'пицца') {
            return(
                <span className="kt-user-card-v2__email">
                    {category.title} - {good.isMod ? '36 см' : '30 см' }
                </span>
            );
        }
        return (
            <span className="kt-user-card-v2__email">
                {category.title}
            </span>
        );
    }

    onErrorImage = (e) => {
        e.target.src = '/images/rsz_noimg.jpg';
    };

    renderGood = (good, index) => {
        if (good.isWok) return this.renderWok(good, index);

        return (
            <tr key={index}>
                <td>
                    <span style={{ width: '252px' }}>
                        <div className="kt-user-card-v2">
                            <div className="kt-user-card-v2__pic">
                                <img alt={good.data.title} src={good.data.image} onError={this.onErrorImage} />
                            </div>
                            <div className="kt-user-card-v2__details">
                                <div className="kt-user-card-v2__name" href="/">{good.data.title}</div>
                                { this.renderCategory(good) }
                            </div>
                        </div>
                    </span>
                </td>
                <td>{good.price} ₸</td>
                <td>{good.count}</td>
                <td className="kt-align-right kt-font-brand kt-font-bold">{good.price * good.count} ₸</td>
            </tr>
        );  
    }

    renderGoods() {
        const { loadingGoods, order } = this.props;

        return (
            <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Товары
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                {
                    loadingGoods ? (
                        <div style={styles.loading}><Loader/></div>
                    ) : (
                    <div className="kt-widget11">
                                <div className="table-responsive">					 
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td style={{ width: '55%' }}>Наименование</td>
                                                <td style={{ width: '15%' }}>Цена</td>
                                                <td style={{ width: '15%' }}>Кол-во</td>
                                                <td style={{ width: '15%' }} className="kt-align-right">Итого</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                order.goods.map(this.renderGood)
                                            }
                                            {
                                                order.gifts && order.gifts.id && this.renderGift(order.gifts)
                                            }
                                            <tr>
                                                <td colSpan={1}>
                                                    
                                                </td>
                                               
                                                <td colSpan={2} style={{ textAlign: 'right' }}>
                                                    <div>Товары</div>
                                                    <div>Доставка</div>
                                                    {
                                                        order.sale && (
                                                            <div>{order.sale.name}</div>
                                                        )
                                                    }
                                                    {
                                                        order.bonusCount && (
                                                            <div>Оплачено бонусами</div>
                                                        )
                                                    }
                                                    <div style={{ marginTop: 8, fontSize: 16, color: '#ec7979' }}>Итого</div>
                                                </td>
                                                <td className="kt-align-right kt-font-brand kt-font-bold">
                                                <div>{order.goodsPrice} ₸</div>
                                                <div>{order.deliveryPrice} ₸</div>
                                                {
                                                    order.sale && (
                                                        <div>{order.salePrice} ₸</div>
                                                    )
                                                }
                                                {
                                                    order.bonusCount && (
                                                        <div>{order.bonusCount} ₸</div>
                                                    )
                                                }
                                                <div style={{ marginTop: 8, fontSize: 16, color: '#ec7979' }}>{order.totalPrice} ₸</div>
                                                </td>
                                            </tr>
                                        </tbody>									     
                                    </table>
                                </div>
                            </div>
                    )
                }
                </div>
            </div>
        );
    }

    render() {
        const { order } = this.props;

        if (this.props.loading || !order) {
            return (
                <div style={styles.loading}><Loader/></div>
            );
        }
        
        return (
            <Fragment>
                {this.renderSubHeader()}
                
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                
                    <div className="row">
                        <div className="col-xl-12">
                            <ErrorContainer container={true} field="orders" style={{ }} hasCloseButton={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            {this.renderOrder()}
                        </div>

                        <div className="col-xl-8">
                           {this.renderGoods()}
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    order: state.orders.detail,
    loading: state.loaders.order,
    goods: state.good.list,
    categories: state.good.categories,
    loadingGoods: state.loaders.goods ||  state.loaders.category,
    role: state.user.info.role
});

const mapDispatchToProps = {
    fetchOrder: (id) => fetchOrder(id),
    fetchGoods: () => fetchGoods(),
    deleteOrder: (id) => deleteOrder(id),
    updateOrder: (id, data) => updateOrder(id, data),
    fetchCategories: () => fetchCategories(),
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailOrder);