import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import { addGood, setActiveModalAction, updateGood, fetchCategories } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import { getImageUrl } from '../../utils/common';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';
import Switch from '../../components/utils/Switch';
import Select from 'react-select';
import { MEASURES } from '../goods/constants';
import { isEmpty } from 'lodash';
import { TimeInput } from '../../components/utils/TimeInput';

const colourStyles = {
  control: (styles) => ({ ...styles, border: "1px solid #e2e5ec" }),
};

const SelectCategories = ({ selected, onChange, disabled }) => {
  const dispatch = useDispatch();
  const categories = useSelector(fromStore.categoryListSelector);
  const loading = useSelector(fromStore.loaderCategoriesSelector);

  const onChangeSelect = (value) => {
    onChange(value.id);
  };

  const selectedCategory = categories && selected ? categories.find((item) => item.id === selected) : null;

  useEffect(() => {
    isEmpty(categories) && dispatch(fetchCategories());
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch]);

  return (
    <div className="form-group">
        <label htmlFor="g-category" className="form-control-label">Категория *</label>
        <Select
            id="g-category"
            value={selectedCategory}
            options={categories}
            getOptionLabel={(item) => `${item.title}`}
            getOptionValue={(item) => item.id}
            onChange={onChangeSelect}
            isClearable={false}
            placeholder="Выберите категорию..."
            noOptionsMessage={() => 'Нет категорий'}
            disabled={disabled}
            isLoading={loading}
            styles={colourStyles}
        />
    </div>
  );
}

function GoodModal({ history }) {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).good;
  const loading = useSelector(fromStore.loaderModalGoodSelector);
  const loadingUpdate = useSelector(fromStore.loaderUpdateGoodSelector);
  const good = useSelector(fromStore.goodById(id));

  const loader = loading || loadingUpdate;

  const measureOptions = Object.entries(MEASURES).map(([value, label]) => ({ value, label }));
  const initData = good ? {
    name: good.name,
    article: good.article,
    category: good.category.id,
    active: good.active,
    price: good.price,
    recomended: good.recomended,
    oldPrice: good.oldPrice,
    gift: good.gift,
    hot: good.hot,
    weight: good.weight,
    measure: good.measure,
    weightdop: good.weightdop,
    measuredop: good.measuredop,
    description: good.description,
    priority: good.priority ? good.priority : 0,
    activeOnTime: good.activeOnTime,
    activeOnTimeStart: good.activeOnTimeStart,
    activeOnTimeEnd: good.activeOnTimeEnd
  } : {
    name: '',
    category: null,
    active: true,
    measure: 'gramm',
    priority: 0,
  };
  const [data, setData] = useState(initData);
  const selectedMeasure = { value: data.measure, label: MEASURES[data.measure] };
  const selectedMeasureDop = { value: data.measuredop, label: MEASURES[data.measuredop] };
  const isEditMode = id !== 'new';
  const utcOffset = moment().utcOffset() / 60

  const onChangeInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.value;
    setData(updated);
  };

  const onChangeNumberInput = (field) => (e) => {
    const updated = {...data};
    if(e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
      updated[field] = e.target.value;
      setData(updated);
    }
  };

  const onChangeSwitch = (field) => (value) => {
    const updated = {...data};
    updated[field] = value;
    setData(updated);
  };

  const onChangeCheckbox = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.checked;
    setData(updated);
  };

  const onSetFile = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.files[0];
    setData(updated);
  };

  const isValid = () => {
    const { name, category, price, weight, measure, description, image } = data;
    if (isEditMode) {
      if (name || image || category || price !== undefined || weight !== undefined || measure || description) return true;
    }
    if (name && image && category && price !== undefined && weight !== undefined && measure && description) return true;

    return false;
  }

  const onClose = () => !loader && dispatch(setActiveModalAction({ field: 'good', value: null }));
  useOnClickOutside(ref, onClose);

  const onSubmit = async (e) => {
    e.preventDefault();
    const rq = { ...data };
    if (rq.weight || rq.weight === '0') {
      rq.weight = parseFloat(rq.weight);
    }
    if (rq.weightdop || rq.weightdop === '0') {
      rq.weightdop = parseFloat(rq.weightdop);
    }
    if (rq.weightdop === "") {
      rq.weightdop = null;
    }
    if (rq.oldPrice || rq.oldPrice === '0') {
      rq.oldPrice = parseFloat(rq.oldPrice);
    } else {
      rq.oldPrice = null;
    }
    if (rq.price || rq.price === '0') {
      rq.price = parseFloat(rq.price);
    }
    if (!isEditMode) {
      const result = await dispatch(addGood(rq));
      if (result) {
        onClose();
        history.push(`/good/${result}`);
      }
    } else {
      if (rq.image) {
        rq.imageData = good.image;
      }
      if (rq.secondImage) {
        rq.secondImageData = good.secondImage;
      }
      const result = await dispatch(updateGood(id, rq));
      if (result) {
        onClose();
      }
    }
  };

  const onChangeSelect = (field) => (value) => {
    const updated = {...data};
    if (field === 'measure' || field === 'measuredop') {
      updated[field] = value.value;
    } else {
      updated[field] = value;
    }
    setData(updated);
  }

  const setFields = (fields) => {
    const updated = {...data};
    for (let field in fields) {
      updated[field] = fields[field];
    }
    setData(updated);
  }

  const setActive = (value) => {
    if(!value && !!data.activeOnTime) {
      const fields = {
        active: value,
        activeOnTime: false
      }
      setFields(fields)
    } else {
      onChangeSwitch('active')(value);
    }
  }

  const onChangeTime = (field) => (date) => {
    const newDate = moment.utc(date).add({ hours: utcOffset });
    return onChangeSwitch(field)(newDate);
  }

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование товара' : 'Добавление товара'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="g-name" className="form-control-label">Заголовок *</label>
                  <input disabled={loader} type="text" value={data.name} className="form-control" id="g-name" onChange={onChangeInput('name')} />
                </div>

                <div className="form-group">
                  <label htmlFor="g-priority" className="form-control-label">Приоритет</label>
                  <input disabled={loader} type="number" value={data.priority} className="form-control" id="g-priority" onChange={onChangeInput('priority')} />
                </div>

                <div className="form-group">
                  <label htmlFor="g-name" className="form-control-label">Артикул (Frontpad)</label>
                  <input disabled={loader} type="text" value={data.article} className="form-control" id="g-name" onChange={onChangeInput('article')} />
                </div>

                <SelectCategories disabled={loader} selected={data.category} onChange={onChangeSelect('category')}/>
               
                <div className="form-group">
                  <label htmlFor="g-image" className="form-control-label">Изображение *</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {good && (
                      <img style={{ marginRight: '20px' }} width="100px" src={ getImageUrl(good.image) } alt="" />
                    )}
                    <input disabled={loader} onChange={onSetFile('image')} type="file" style={{ padding: '6px' }} className="form-control" id="g-image" />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="g-image" className="form-control-label">Изображение 2</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {good && (
                      <img style={{ marginRight: '20px' }} width="100px" src={ getImageUrl(good.secondImage) } alt="" />
                    )}
                    <input disabled={loader} onChange={onSetFile('secondImage')} type="file" style={{ padding: '6px' }} className="form-control" id="g-image" />
                  </div>
                </div>

                <div className='row'>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Цена *</label>
                      <input disabled={loader} type="number" value={data.price} className="form-control" onChange={onChangeNumberInput('price')} />
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Старая цена</label>
                      <input disabled={loader} type="number" value={data.oldPrice} className="form-control" onChange={onChangeNumberInput('oldPrice')} />
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Вес/Объем *</label>
                      <input disabled={loader} value={data.weight} className="form-control" onChange={onChangeNumberInput('weight')} />
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Единица *</label>
                      <Select
                         value={selectedMeasure}
                         options={measureOptions}
                         onChange={onChangeSelect('measure')}
                         isClearable={false}
                         placeholder="Выберите единицу..."
                         disabled={loader}
                         styles={colourStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Вес/Объем (доп.)</label>
                      <input disabled={loader} value={data.weightdop} className="form-control" onChange={onChangeNumberInput('weightdop')} />
                    </div>
                  </div>
                  <div className='col-3'>
                    <div className="form-group">
                      <label className="form-control-label">Единица (доп.)</label>
                      <Select
                         value={selectedMeasureDop}
                         options={measureOptions}
                         onChange={onChangeSelect('measuredop')}
                         isClearable={false}
                         placeholder="Выберите единицу..."
                         disabled={loader}
                         styles={colourStyles}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="form-control-label">Описание *</label>
                  <textarea minLength={3} maxLength={500} disabled={loader} value={data.description} className="form-control" onChange={onChangeInput('description')} />
                </div>

                <div className="form-group">
                    <label className="form-control-label">Параметры товара</label>
                    <div className="kt-checkbox-inline" style={{ marginTop: '5px' }}>
                        <label className="kt-checkbox">
                            <input disabled={loader} type="checkbox" checked={!!data.hot} onChange={onChangeCheckbox('hot')}/> Острое
                            <span></span>
                        </label>
                        <label className="kt-checkbox">
                            <input disabled={loader}  type="checkbox" checked={!!data.recomended} onChange={onChangeCheckbox('recomended')}/> Рекомендуем
                            <span></span>
                        </label>
                        <label className="kt-checkbox">
                            <input disabled={loader}  type="checkbox" checked={!!data.gift} onChange={onChangeCheckbox('gift')}/> Подарок
                            <span></span>
                        </label>
                    </div>
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Включен</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch disabled={loader} nomargin={true} onChange={(value) => setActive(value)} checked={!!data.active}/>
                    </div>
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Включение по времени</label>
                    <div className="col-lg-9 col-xl-9">
                        <Switch disabled={!data.active} nomargin={true} onChange={onChangeSwitch('activeOnTime')} checked={!!data.activeOnTime}/>
                    </div>
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Доступно с</label>
                    <div className="col-lg-9 col-xl-9">
                        <TimeInput
                          placeholderText="Начало времени"
                          disabled={!data.activeOnTime}
                          selected={data.activeOnTimeStart ? moment(data.activeOnTimeStart).subtract({ hours: utcOffset }).toDate() : moment().toDate()}
                          onChange={onChangeTime('activeOnTimeStart')}
                          className="form-control"
                        />
                    </div>
                </div>
                <div className="form-group row" style={{ marginBottom: 0 }}>
                    <label className="col-xl-3 col-lg-3 col-form-label">Доступно до</label>
                    <div className="col-lg-9 col-xl-9">
                        <TimeInput 
                          placeholderText="Окончание времени"
                          disabled={!data.activeOnTime}
                          selected={data.activeOnTimeEnd ? moment(data.activeOnTimeEnd).subtract({ hours: utcOffset }).toDate() : moment().toDate()}
                          onChange={onChangeTime('activeOnTimeEnd')}
                          className="form-control"
                        />
                    </div>
                </div>
                <ErrorContainer field="modalGood" hasCloseButton={true}/>
            </div>

            <div className="modal-footer">
              <button disabled={loader} type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loader}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loader })}>
                  {isEditMode ? 'Сохранить' : 'Добавить товар'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default withRouter(GoodModal);