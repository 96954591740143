import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { getImageUrl } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { deleteGood, setActiveModalAction, updateGood } from '../../../action';
import swal from 'sweetalert';
import { MEASURES } from '../constants';

const Item = ({ item, history }) => {
    const dispatch = useDispatch();
    const loaderUpdateGood = useSelector(fromStore.loaderUpdateGoodSelector);
    const loaderDeleteGood = useSelector(fromStore.loaderDeleteGoodSelector);
    const loaderUpdate = loaderUpdateGood === item.id;
    const loadertDelete = loaderDeleteGood === item.id;

    const openDetail = () => {
        history.push(`/good/${item.id}`);
    };

    const onOpenModalGood = (id) => (e) => {
        e.stopPropagation();
        const goodId = id || 'new';
        dispatch(setActiveModalAction({ field: 'good', value: goodId }));
    }

    const onToogleActiveGood = () => {
        const data = { active: !item.active };
        dispatch(updateGood(item.id, data))
    };

    const onDeleteGood = () => {
        swal({
            title: `Удаление товара`,
            text: `Вы действительно хотите удалить ${item.name}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteGood(item.id));
            }
        });
    }

    return (
        <tr key={`${item.id}_good`} className="kt-datatable__row" onDoubleClick={openDetail}>
            <td width="80px" className="kt-datatable__cell"><span>{item.id}</span></td>
            <td width="80px" className="kt-datatable__cell">
                <img width="50px" alt={item.name} src={getImageUrl(item.image)} />
            </td>
            <td width="auto" className="kt-datatable__cell"><span>{item.name}</span></td>
            <td width="165px" className="kt-datatable__cell"><span>{`${item.price} ₸`}</span></td>
            <td width="165px" className="kt-datatable__cell"><span>{item.oldPrice ? `${item.oldPrice} ₸` : '-'}</span></td>
            <td width="165px" className="kt-datatable__cell"><span>{item.article || '-'}</span></td>
            <td width="165px" className="kt-datatable__cell">
                <span>{item.weight} {MEASURES[item.measure]}</span>
            </td>
            <td align='right' width="180px" className="kt-datatable__cell">
                <button
                    onClick={onToogleActiveGood}
                    style={{ marginRight: '3px' }}
                    data-tip={item.active ? 'Выключить' : 'Включить'}
                    disabled={loaderUpdate || loadertDelete}
                    className={cn("btn btn-sm btn-icon btn-icon-md", {
                        'btn-danger': !item.active,
                        'btn-success': item.active,
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                    })}>
                    {!loaderUpdate && <i className="la la-power-off"></i>}
                </button>
                <button onClick={openDetail} disabled={loaderUpdate || loadertDelete} data-tip="Просмотр" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-eye"></i> </button>
                <button onClick={onOpenModalGood(item.id)} disabled={loaderUpdate || loadertDelete} data-tip="Редактировать" className="btn btn-sm btn-clean btn-icon btn-icon-md"> <i className="la la-edit"></i> </button>
                <button
                    onClick={onDeleteGood}
                    disabled={loaderUpdate || loadertDelete}
                    data-tip="Удалить" 
                    className={cn("btn btn-sm btn-clean btn-icon btn-icon-md", {
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger': loadertDelete
                    })}>
                        {!loadertDelete && <i className="la la-trash"></i>}
                    </button>
            </td>
        </tr>
    );
}

export const ItemRow = withRouter(Item);