import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import { updateOrderFromTable } from '../../action/orders';
import { connect } from 'react-redux';
import swal from 'sweetalert';

class OrdersTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showDetails: Array(this.props.orders.length).fill(false)
        };
      }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/orders/${+item.id}`)
    }
    
    
    handleClickCopy = (text) => () => {
        navigator.clipboard.writeText(text)
    }

    toggleDetails(index) {
        this.setState(prevState => {
          const updatedShowDetails = [...prevState.showDetails]
          updatedShowDetails[index] = !updatedShowDetails[index]
          return { showDetails: updatedShowDetails }
        })
    }

    changePoint = (value, item) => {
        let data = {}
        if(!value)
            data = {
                city: null,
                point: null,
                zone: null
            };
        if(value)
            data = {
                city: this.props.filials.find((el) => el.id === value).city,
                point: this.props.filials.find((el) => el.id === value),
                zone: null
            };
        this.props.updateOrderFromTable(item.id, data, item.isArchive);   
    }

    changeStatus = (e, item) => {
        if(item.status !== e.target.value)
        {
            const data = {
                status: e.target.value
            };
            if (data.status === 'cancel') {
                swal({
                    title: "Изменение статуса заказа",
                    text: "Вы действительно хотите отменить заказ?",
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["Отмена", "Отменить заказ"],
                }).then((willDelete) => {
                    if (willDelete) {
                        this.props.updateOrderFromTable(item.id, data, item.isArchive);
                    }
                });
            } else {
                this.props.updateOrderFromTable(item.id, data, item.isArchive);
            } 
        }      
    }

    renderIndicator(status) {
        if (status === 'new') {
            return <td className="kt-indicator"></td>
        }
        if (status === 'done') {
            return <td className="kt-indicator success"></td>
        }
        if (status === 'cancel') {
            return <td className="kt-indicator danger"></td>
        }
        if (status === 'return') {
            return <td className="kt-indicator dark"></td>
        }
    }

    getAllToppings(top) {
        const { meat, topping } = top;
        const toppings = [];
        if (Object.keys(meat).length) {
            Object.keys(meat).forEach(item => {
                toppings.push(meat[item]);
            })
        }
        if (Object.keys(topping).length) {
            Object.keys(topping).forEach(item => {
                toppings.push(topping[item]);
            })
        }
        return toppings;
    }
    getToppings(item) {
        const toppings = this.getAllToppings(item);
        if (toppings.length === 0) return '';
        return toppings.map(top => `${top.data.title.toLowerCase()} x${top.count}`).join(', ');
    }
    renderWok = (good, index) => {
        const { main, souce } = good.wokData;
        const firstSouce = souce[Object.keys(souce)[0]].title;
        const secondSouce = Object.keys(souce)[1];
        const mainWok = `${main.title} + Соус ${firstSouce}`;
        let toppings = this.getToppings(good.wokData);
        if (secondSouce) {
            toppings = `доп. соус ${souce[secondSouce].title.toLowerCase()}${toppings ? `, ${toppings} `: ''}`;
        }
        return (
            <tr key={index} style={{ minWidth: '300px'}}>
                <td>
                        <div className="order-table-order-card__name">{mainWok}</div>
                        <span className="order-table-order-card__toppings">
                            {toppings}
                        </span>
                </td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.price}</td>                
            </tr>
        );
    }
    renderGoods(good, index){
        if (good.isWok) return this.renderWok(good, index);
        return (
            <tr key={index} style={{ minWidth: '300px'}}>
                <td>
                    <div className="order-table-order-card__name">{good.data.title}</div>
                </td>
                <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{good.count}</td>
                <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>{good.price}</td>                     
            </tr>
        );  
    }
    renderPayment(payment, ChangeCash) {
        if(payment === 'terminal')
            return <span>Терминал</span>
        if(payment === 'cash')
        {
            switch(ChangeCash){
                case('c1000'):{
                    return <span>Нал, сдача с 1000р</span>
                }
                case('c1500'):{
                    return <span>Нал, сдача с 1500р</span>
                }
                case('c2000'):{
                    return <span>Нал, сдача с 2000р</span>
                }
                case('c5000'):{
                    return <span>Нал, сдача с 5000р</span>
                }
                default:{
                    return <span>Нал б/с</span>
                }
            }
        }
    }
    renderSelectPoint(item) {
        if (!item.point && !item.zone) return '';
        if(item.point)
        {
            return ({
                value: item.point.id,
                label: `${item.city ? item.city.name : '-'}, ${item.point.street}, ${item.point.house}`
            })
        }
        else
        {
            return ({
                value: `${this.props.filials.find((el) => el.id === item.zone.point).id}`,
                label: `${item.city ? item.city.name : '-'}, ${this.props.filials.find((el) => el.id === item.zone.point).street}, ${this.props.filials.find((el) => el.id === item.zone.point).house}`
            })
        }       
    }
    
    renderAdress(item) {
        if (!item.street && !item.point) return '-';
        if(item.deliveryMethod === "delivery")
            return `${item.city ? item.city.name + ', ' : ''}${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.floor ? `, этаж ${item.floor}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
        if(item.deliveryMethod === "own")
            return `${item.city ? item.city.name + ', ' : ''}${item.point.street}, ${item.point.house}`;
    }

    renderAdressSelect(item){
        return `${item.city ? item.city.name + ', ' : ''}${item.street}, ${item.house}`;
    }

    renderStatus(item) {
        switch(item.status){
            case('done'):{
                return <select onDoubleClick={((event) => {event.stopPropagation()})} onChange={((e) => {this.changeStatus(e, item)})} value={item.status} placeholder="Статус заказа" className="status-badge success short-text">
                { item.status === 'new' && <option value="new">Новый заказ</option>}
                { (item.status === 'new' ||  item.status === 'done') && <option value="done">Выполнен</option>}
                { (item.status === 'cancel' ||  item.status === 'new') && <option value="cancel">Отменен</option>}
                { (item.status === 'done' ||  item.status === 'return') && <option value="return">Возврат</option>}
                </select>
            }
            case('cancel'):{
                return <select onDoubleClick={((event) => {event.stopPropagation()})} onChange={((e) => {this.changeStatus(e, item)})} value={item.status} placeholder="Статус заказа" className="status-badge danger short-text">
                { item.status === 'new' && <option value="new">Новый заказ</option>}
                { (item.status === 'new' ||  item.status === 'done') && <option value="done">Выполнен</option>}
                { (item.status === 'cancel' || item.status === 'new') && <option value="cancel">Отменен</option>}
                { (item.status === 'done' || item.status === 'return') && <option value="return">Возврат</option>}
                </select>
            }
            case('return'):{
                return <select onDoubleClick={((event) => {event.stopPropagation()})} onChange={((e) => {this.changeStatus(e, item)})} value={item.status} placeholder="Статус заказа" className="status-badge dark short-text">
                { item.status === 'new' && <option value="new">Новый заказ</option>}
                { (item.status === 'new' || item.status === 'done') && <option value="done">Выполнен</option>}
                { (item.status === 'cancel' ||  item.status === 'new') && <option value="cancel">Отменен</option>}
                { (item.status === 'done' ||  item.status === 'return') && <option value="return">Возврат</option>}
                </select>
            }
            default:{
                return <select onDoubleClick={((event) => {event.stopPropagation()})} onChange={((e) => {this.changeStatus(e, item)})} value={item.status} placeholder="Статус заказа" className="status-badge short-text">
                { item.status === 'new' && <option value="new">Новый заказ</option>}
                { (item.status === 'new' || item.status === 'done') && <option value="done">Выполнен</option>}
                { (item.status === 'cancel' || item.status === 'new') && <option value="cancel">Отменен</option>}
                { (item.status === 'done' || item.status === 'return') && <option value="return">Возврат</option>}
                </select>
            }
        }
    }

    renderBody() {

        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.orders.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Заказов не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.orders.map((item, orderIndex) => {
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <thead style={{ boxShadow: '0px 1px #efeff5' }}>
                                {this.renderIndicator(item.status)}
                                <th 
                                style={{ width: '300px', color: '#000', fontSize: '1.1rem', paddingLeft: '5px', display: 'flex', alignItems: 'end', gap: '0.5rem'}}
                                onDoubleClick={((event) => {
                                    event.stopPropagation()
                                })}                                
                                >
                                    <span style={{ fontWeight: '600' }}>{moment(item.created_at).format('HH:mm')}</span>
                                    <span style={{ fontWeight: '300' }}>{moment(item.created_at).format('DD.MM.YYYY')}</span>
                                    <span style={{ fontWeight: '300' }}>{'(№ ' + item.id + ')'}</span>
                                    <button 
                                    className='order-table_arrow-show-details'
                                    onDoubleClick={((event) => {
                                        event.stopPropagation()
                                    })}   
                                    onClick={() => {
                                        this.toggleDetails(orderIndex)
                                    }}                   
                                    >                 
                                        <i className={`kt-menu__ver-arrow la la-angle-down${this.state.showDetails[orderIndex] ? ' Showed' : ''}`}></i>
                                    </button>
                                    <button className='copy-button-order_date-and-id' onClick={this.handleClickCopy(moment(item.created_at).format('HH:mm') + ' ' + moment(item.created_at).format('DD.MM.YYYY') + '(№ ' + item.id + ')')}>
                                        <i className='kt-nav__link-icon flaticon2-copy'/>
                                    </button>                                    
                                </th>
                            </thead>
                            {this.renderIndicator(item.status)}
                                <td style={{ width: '300px' }} className="kt-datatable__cell">
                                    {!this.state.showDetails[orderIndex] && <button 
                                    className='order-table_button-show-details'
                                    onDoubleClick={((event) => {
                                        event.stopPropagation()
                                    })}   
                                    onClick={() => {
                                        this.toggleDetails(orderIndex)
                                    }}
                                    >
                                        Показать состав
                                    </button>}
                                
                                {
                                    this.state.showDetails[orderIndex] && 
                                    item.goods.map((good, index) => this.renderGoods(good, index))                                    
                                }
                                {
                                    this.state.showDetails[orderIndex] && item.unit &&
                                    <tr style={{ minWidth: '300px'}}>
                                        <td>
                                            <div className="order-table-order-card__name">Приборы</div>
                                        </td>
                                        <td style={{ paddingLeft: '5px', width: '25px', textAlign: 'start', color: '#000'}}>{item.unit}</td>
                                        <td style={{ paddingRight: '5px', width: '40px', textAlign: 'end', color: '#000'}}>0</td>                     
                                    </tr>
                                }
                            </td>

                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>{item.totalPrice} ₸</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span style={{ width: '100px' }}>{item.salePrice} ₸</span>
                            </td>
                            
                            <td className="kt-datatable__cell" onClick={(() => console.log(item))}>
                                <span style={{ width: '123px' }}>
                                    {this.renderPayment(item.payment, item.changeCash)}
                                </span>
                            </td>

                            <td className="kt-datatable__cell">
                                {item.point && <span style={{ fontSize: '1.2rem', fontWeight: '600', width: '60px' }}>{`${item.point.minDeliveryTime}/${item.point.minSamTime}`}</span>}
                                {item.zone && <span style={{ fontSize: '1.2rem', fontWeight: '600', width: '60px' }}>{`${this.props.filials.find((el) => el.id === item.zone.point).minDeliveryTime}/${this.props.filials.find((el) => el.id === item.zone.point).minSamTime}`}</span>}
                                {!item.point && !item.zone && <span style={{ fontSize: '1.2rem', fontWeight: '600', width: '60px' }}> </span>}
                            </td>

                            <td className="kt-datatable__cell">
                                <div style={{ width: 250 }}>
                                    <Select
                                        isLoading={this.props.loadingFilials}
                                        isDisabled={this.props.filters.zone}
                                        value={this.renderSelectPoint(item)}
                                        closeMenuOnSelect={true}
                                        onChange={(e) => {this.changePoint(e ? e.value : null, item)}}
                                        styles={{
                                            menuPortal: base => ({ ...base, zIndex: 9999 }) 
                                          }}
                                        menuPlacement="auto"
                                        options={[
                                            { value: null, label: "Не определен" },
                                            ...this.props.filials.map(el => ({
                                                value: el.id,
                                                label: this.renderAdressSelect(el)
                                            }))
                                        ]}                                     
                                        placeholder="Филиал"
                                        noOptionsMessage={() => 'Нет городов'}
                                    />                                    
                                </div>
                            </td>

                            <td className="kt-datatable__cell">
                                <span style={{ width: '200px' }}>                                    
                                    {this.renderAdress(item)}
                                    {item.comment ? ', Комментарий: ' + item.comment : ''}
                                </span>
                            </td> 

                            <td onDoubleClick={((event) => {event.stopPropagation()})} className="kt-datatable__cell">
                                <span style={{ width: '150px', display: 'flex', gap: '5px', alignItems: 'center' }}>
                                    {item.phone} 
                                    <button className='copy-button-order_date-and-id' onClick={this.handleClickCopy(String(item.phone))}>
                                        <i className='kt-nav__link-icon flaticon2-copy'/>
                                    </button>
                                </span>
                            </td>
                            
                            <td className="kt-datatable__cell">
                                <div style={{ width: '123px' }}>
                                    {this.renderStatus(item) }
                                </div>
                            </td>

                            {this.props.role === 4 &&
                            <td className="kt-datatable__cell">
                                <button
                                className='btn btn-sm btn-clean btn-icon btn-icon-md'
                                onClick={(() => {
                                    this.props.onDelete(item.id)
                                })}
                                >                                    
                                    <i className='kt-nav__link-icon flaticon2-trash'></i>
                                </button>                           
                            </td>
                            }
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderBody()}
            </table>
        );
    }
}

const mapStateToProps = state => ({
    orders: state.orders.list,
    filters: state.orders.filters,
    loading: state.loaders.orders,
    filials: state.handbook.filials,
    role: state.user.info.role.id
});
const mapDispatchToProps = {
    updateOrderFromTable: (id, data, isArchive) => updateOrderFromTable(id, data, isArchive),
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrdersTable));